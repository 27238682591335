import { LINKAREER_CLOUDINARY_NAME } from './config'

const URL_WHITE_LIST = [
  'https://res.cloudinary.com/dqbnvpjgr/image/fetch',
  'https://i.imgur.com',
  'https://imgur.com',
  'https://s3.ap-northeast-2.amazonaws.com/media.linkareer.com',
  'http://k.kakaocdn.net',
  'https://phinf.pstatic.net',
  'https://s3.ap-northeast-2.amazonaws.com/media.lnkr.cc',
  'https://api.linkareer.com',
  'https://api.lnkr.cc',
  'https://gateway-api.lnkr.cc',
  'https://media-cdn.lnkr.cc/career-portfolio-image', // 람다에서 스펙관리하기 pdf 다운로드시에만 적용이 필요하기 때문에 추가
  'https://media-cdn.linkareer.com/career-portfolio-image', // 람다에서 스펙관리하기 pdf 다운로드시에만 적용이 필요하기 때문에 추가
]

const CLOUDINARY_URL = `https://res.cloudinary.com/${
  LINKAREER_CLOUDINARY_NAME || 'linkareer'
}/image/fetch`

export interface cloudinaryOptions {
  width?: number | string
  quality?: number
  dpr?: number | 'auto'
}

export const cloudinaryURL = (url?: string, options?: cloudinaryOptions) => {
  if (!url) return ''
  const regex = new RegExp(URL_WHITE_LIST.join('|'), 'g')
  if (!url.match(regex)) {
    return url
  }

  try {
    const { width, dpr, quality = 50 } = options || {}
    const args = ['f_auto', `q_${quality}`]
    if (width) {
      args.push(`w_${width}`)
    }
    if (dpr) {
      args.push(`dpr_${dpr}`)
    }

    return `${CLOUDINARY_URL}/${args.join(',')}/${decodeURIComponent(url)}`
  } catch (error) {
    return url
  }
}
